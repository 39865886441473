<template>
  <div class="education-courses">
    <div class="g-container">
      <h1>Education &amp; Courses</h1>
      <div class="edu">
        <div>
          <h2>Institute: <span>Sheraton Academy</span></h2>
          <h2>Faculty: <span>Management Information Systems (MIS)</span></h2>
        </div>
        <div>
          <img src="@/assets/img/9047156.jpg" alt="edu" draggable="true" />
        </div>
      </div>
      <div class="web-design">
        <div>
          <h2>Professional Diploma in Web Design at YAT Center</h2>
        </div>
        <div>
          <img src="@/assets/img/web1.jpg" alt="web design" draggable="true" />
        </div>
      </div>
      <div class="english">
        <div>
          <h2>12 Level of English Course</h2>
        </div>
        <div>
          <img src="@/assets/img/english.jpg" alt="English" draggable="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationCourses",
};
</script>

<style lang="scss" scoped>
.education-courses {
  padding: 50px 5px;
  .g-container {
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
    .edu {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      div {
        padding: 10px;
        display: grid;
        align-content: center;
        justify-content: center;
        &:first-child {
          color: #fa5f1c;
          h2 {
            font-size: 1.2rem;
            span {
              font-size: 1rem;
              font-weight: 400;
            }
          }
        }
        img {
          width: 300px;
        }
      }
    }
    .web-design {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      border-top: 1px solid #e7e7e7;
      padding: 10px 0;
      div {
        padding: 10px;
        display: grid;
        align-content: center;
        justify-content: center;
        h2 {
          color: #5843be;
          font-size: 1.5rem;
        }
      }
    }
    .english {
      margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      border-top: 1px solid #e7e7e7;
      padding: 10px 0;
      div {
        padding: 10px;
        display: grid;
        align-content: center;
        h2 {
          color: #03045e;
          font-size: 1.5rem;
        }
      }
    }
    @media (max-width: 768px) {
      .edu,
      .web-design,
      .english {
        // grid-template-columns: 220px;
        // width: 100%;
        div {
          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
</style>
